import THEME from '../theme';
import { aspectRatioLogoAbout, borderRadiusButtons, linkAppleStore, linkPlayStore, footerSocialData } from '../../config/data';

const breakpoint = 1080;
const colors = [THEME.COLORS.PRIMARY_900, THEME.COLORS.PRIMARY_700]; // Define an array of background colors and text colors

//******************************************************************
// To add gradient background to section, add above containerStyles:: 
// gradientColors = {
//     colors: [
//         'rgba(68, 155, 206, 1)',
//         'rgba(19, 63, 112, 1)',
//         'rgba(19, 23, 54, 1)',
//     ],
//     start: { x: 0, y: 0 },
//     end: { x: 1, y: 0 },
// };
// gradientBackgroundStyles = {
//     position: 'absolute',
//     left: 0,
//     right: 0,
//     top: 0,
//     bottom: 0,
// };


// To add image background to section, add above containerStyles: 
// backgroundImage: {
//     mobile: {
//         uri: require("../../../assets/FotoAboutMobile.jpg")
//     },
//     web: {
//         uri: require("../../../assets/FotoAbout.jpg")
//     },
// },


// To add solid color background to section, add in containerStyles: 
// containerStyles = (width) => ({
//     flexBasis: "auto",
//     backgroundColor: "green" // (Optional) The solid color to use as the background
// });

//******************************************************************

const instagramLink = footerSocialData.find(obj => obj.name === "instagram").link;
const instagramName = footerSocialData.find(obj => obj.name === "instagram").profile;
const whatsappLink = footerSocialData.find(obj => obj.name === "whatsapp").link;
const whatsappNumber = footerSocialData.find(obj => obj.name === "whatsapp").number;


export const clientConfig = [
    {
        name: "yogando-br",
        pages: [
            {
                name: "about",
                sections: [
                    {
                        header: {
                            //add a logo property if there is a logo at header
                            logo: {
                                style: (width) => ({
                                    aspectRatio: aspectRatioLogoAbout,
                                    resizeMode: "contain",
                                    paddingVertical: width < breakpoint ? "1rem" : "1.5rem",
                                    marginHorizontal: width < breakpoint ? "1rem" : "1.5rem",
                                    marginTop: "1rem",
                                }),
                                uri: require('../../../assets/LogoAbout.png'),
                            },
                            buttonLogin:
                            {
                                style: (width) => ({
                                    padding: "0.3rem",
                                    marginTop: width < breakpoint ? "0.5rem" : "2rem",
                                }),
                                textStyle: (width) => ({
                                    color: THEME.COLORS.TEXT_BUTTON,
                                    fontFamily: THEME.FONTFAMILY.TEXTS,
                                    fontSize: width < breakpoint ? 14 : 16,
                                    paddingHorizontal: width < breakpoint ? "0rem" : "1rem",
                                    paddingLeft: width < breakpoint ? "0.5rem" : "1rem"
                                }),
                            },
                            buttonApp: {
                                style: (width) => ({
                                    backgroundColor: "transparent",
                                    padding: "0.5rem",
                                    borderRadius: borderRadiusButtons,
                                    paddingVertical: 8,
                                    paddingHorizontal: "1rem",
                                    marginTop: width < breakpoint ? "0.5rem" : "2rem",
                                }),
                                textStyle: (width) => ({
                                    color: THEME.COLORS.TEXT_BUTTON,
                                    fontFamily: THEME.FONTFAMILY.TEXTS,
                                    fontSize: width < breakpoint ? 14 : 16,
                                }),
                            }
                        },
                        columns: [
                            // {
                            //     style: (width, columnWidth) => ({
                            //         flexDirection: 'row',
                            //         justifyContent: 'space-between',
                            //         alignItems: 'center',
                            //         padding: width < breakpoint ? "1rem" : "2rem",
                            //         width: width < breakpoint ? '100%' : "50%",
                            //         alignItems: "center",
                            //         order: width < breakpoint ? 2 : 2,
                            //     }),
                            //     items: [
                            //         {
                            //             type: 'image',
                            //             uri: require('../../../assets/Computador.png'),
                            //             aspectRatio: 1.25, //padrão da imagem
                            //             styles: (width) => ({
                            //                 container: {
                            //                     width: width < breakpoint ? "100%" : "100%",
                            //                     marginBottom: width < breakpoint ? "0.5rem" : "3rem",
                            //                     marginTop: width < breakpoint ? "0.5rem" : "3rem",
                            //                 },
                            //                 image: {
                            //                     width: '100%',
                            //                     resizeMode: "contain",
                            //                 },
                            //             }),
                            //         },
                            //     ]
                            // },
                            {
                                style: (width) => ({
                                    justifyContent: 'center',
                                    flex: 1,
                                    alignItems: width < breakpoint ? 'center' : 'flex-start',
                                    paddingTop: width < breakpoint ? "18rem" : "2rem",
                                    paddingBottom: width < breakpoint ? "2rem" : "6rem",
                                    order: width < breakpoint ? 1 : 1,
                                    width: width < breakpoint ? "100%" : "100%",
                                }),
                                items: [
                                    {
                                        type: 'text',
                                        text: 'yogandoBr',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 35 : 80,
                                            width: width < breakpoint ? "100%" : "100%",
                                            color: "#FFFFFF", //colocar uma cor que combine com a foto de fundo
                                            textAlign: width < breakpoint ? "center" : "center",
                                            marginBottom: width < breakpoint ? "2rem" : "2rem",
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'Se aprofunde na filosofia e na prática do Yoga de forma leve, acessível e livre de dogmas!',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 20 : 24,
                                            width: width < breakpoint ? "100%" : "100%",
                                            color: "#FFFFFF", //colocar uma cor que combine com a foto de fundo
                                            textAlign: width < breakpoint ? "center" : "center",
                                            marginBottom: width < breakpoint ? "2rem" : "20rem",
                                            fontFamily: THEME.FONTFAMILY.SUBTITLE,
                                        })
                                    },
                                    {
                                        type: 'plansButton',
                                        text: 'Quero começar agora',
                                        buttonStyle: (width) => ({
                                            backgroundColor: THEME.COLORS.PRIMARY_900,
                                            paddingVertical: width < breakpoint ? 14 : 18,
                                            marginBottom: width < breakpoint ? "2rem" : "5rem",
                                            paddingHorizontal: width < breakpoint ? 20 : 28,
                                            borderRadius: borderRadiusButtons,
                                            alignSelf: width < breakpoint ? "center" : "flex-start",
                                        }),
                                        textStyle: (width) => ({
                                            fontSize: width < breakpoint ? 16 : 18,
                                            fontWeight: "bold",
                                            color: THEME.COLORS.PRIMARY_700,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            textAlign: width < breakpoint ? "flex-end" : "center",
                                        }),
                                    },
                                ]
                            }
                        ],
                        backgroundImage: {
                            mobile: {
                                uri: require("../../../assets/FotoAboutMobile.jpg")
                            },
                            web: {
                                uri: require("../../../assets/FotoAbout.jpg")
                            },
                        },
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            //backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                        }),
                        contentStyles: (width) => ({
                            alignItems: width < breakpoint ? "center" : "center",
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexDirection: width < breakpoint ? "column" : "row",
                            flexBasis: "auto",
                            paddingHorizontal: width < breakpoint ? "2rem" : "5rem",
                        }),
                    },
                    // {
                    //     columns: [
                    //         {
                    //             style: (width, columnWidth) => ({
                    //                 flexDirection: 'row',
                    //                 justifyContent: 'space-between',
                    //                 alignItems: 'center',
                    //                 padding: width < breakpoint ? "1rem" : "0rem",
                    //                 width: width < breakpoint ? '100%' : "50%",
                    //                 alignItems: "center",
                    //                 order: width < breakpoint ? 2 : 1,
                    //             }),
                    //             items: [
                    //                 {
                    //                     type: 'image',
                    //                     uri: require('../../../assets/FotoAppStore.jpg'),
                    //                     aspectRatio: 1.4, //padrão da imagem
                    //                     styles: (width) => ({
                    //                         container: {
                    //                             width: width < breakpoint ? "100%" : "100%",
                    //                             marginBottom: width < breakpoint ? "0.5rem" : "3rem",
                    //                             marginTop: width < breakpoint ? "0.5rem" : "3rem",
                    //                         },
                    //                         image: {
                    //                             width: '100%',
                    //                             resizeMode: "contain",
                    //                         },
                    //                     }),
                    //                 },
                    //             ]
                    //         },
                    //         {
                    //             style: (width) => ({
                    //                 justifyContent: 'space-between',
                    //                 alignItems: 'center',
                    //                 padding: width < breakpoint ? "1rem" : "0rem",
                    //                 order: width < breakpoint ? 1 : 2,
                    //                 width: width < breakpoint ? "100%" : "50%",
                    //             }),
                    //             items: [
                    //                 {
                    //                     type: 'text',
                    //                     text: 'Agora o YOGANDOBR está disponível em todos os seus dispositivos de maneira mais prática e fácil. Acesse o nosso conteúdo ao vivo e gravado e tenha nossa escola na palma da sua mão.',
                    //                     style: (width) => ({
                    //                         fontSize: width < breakpoint ? 26 : 34,
                    //                         color: THEME.COLORS.TEXT_ABOUT,
                    //                         textAlign: "center",
                    //                         marginBottom: 30,
                    //                         fontFamily: THEME.FONTFAMILY.TEXTS,
                    //                         paddingTop: width < breakpoint ? "2rem" : "0rem",
                    //                     })
                    //                 },
                    //                 {
                    //                     type: 'text',
                    //                     text: 'Disponível nas lojas de aplicativos:',
                    //                     style: (width) => ({
                    //                         fontSize: width < breakpoint ? 20 : 24,
                    //                         color: THEME.COLORS.TEXT_ABOUT,
                    //                         textAlign: width < breakpoint ? "center" : "left",
                    //                         marginBottom: 30,
                    //                         fontFamily: THEME.FONTFAMILY.TEXTS,
                    //                     })
                    //                 },
                    //                 {
                    //                     type: 'appleStoreButton',
                    //                     uri: linkAppleStore,
                    //                 },
                    //                 {
                    //                     type: 'googlePlayButton',
                    //                     uri: linkPlayStore,
                    //                 },
                    //             ]
                    //         }
                    //     ],
                    //     containerStyles: (width) => ({
                    //         flexBasis: "auto",
                    //         backgroundColor: "#DDCACA"
                    //     }),
                    //     contentStyles: (width) => ({
                    //         alignItems: width < breakpoint ? "center" : "center",
                    //         justifyContent: width < breakpoint ? "center" : "center",
                    //         flexDirection: width < breakpoint ? "column" : "row",
                    //         flexBasis: "auto",
                    //         paddingHorizontal: width < breakpoint ? "2rem" : "5rem",
                    //     }),
                    // },
                    // {
                    //     columns: [
                    //         {
                    //             style: (width, columnWidth) => ({
                    //                 justifyContent: 'space-between',
                    //                 alignItems: 'center',
                    //                 padding: width < breakpoint ? "1rem" : "2rem",
                    //                 width: width < breakpoint ? '100%' : columnWidth,
                    //                 alignItems: "center",
                    //                 order: width < breakpoint ? 1 : 2,
                    //             }),
                    //             items: [
                    //                 {
                    //                     type: 'text',
                    //                     text: 'Para quem é o yogandoBr app',
                    //                     style: (width) => ({
                    //                         fontSize: width < breakpoint ? 35 : 50,
                    //                         color: THEME.COLORS.PRIMARY_900,
                    //                         textAlign: "center",
                    //                         marginBottom: 35,
                    //                         marginTop: 50,
                    //                         fontWeight: 'bold',
                    //                         fontFamily: THEME.FONTFAMILY.TITLE,
                    //                         padding: "10px",
                    //                     })
                    //                 },
                    //                 {
                    //                     type: 'text',
                    //                     text: 'O YOGANDOBR APP é o lugar perfeito para professores e praticantes de Yoga se aprofundarem na filosofia e na prática do Yoga e descobrirem novas formas de se praticar, de forma leve, acessível e livre de dogmas.',
                    //                     style: (width) => ({
                    //                         fontSize: width < breakpoint ? 16 : 24,
                    //                         color: THEME.COLORS.TEXT_ABOUT,
                    //                         textAlign: "center",
                    //                         marginBottom: 25,
                    //                         fontFamily: THEME.FONTFAMILY.TEXTS,
                    //                         lineHeight: width < breakpoint ? 30 : 35,
                    //                         paddingHorizontal: width < breakpoint ? "0.5rem" : "1rem",
                    //                     })
                    //                 },
                    //                 {
                    //                     type: 'image',
                    //                     uri: require('../../../assets/FotoAboutMe.jpg'),
                    //                     aspectRatio: 1, //AJUSTAR CONFORME IMAGEM
                    //                     styles: (width) => ({
                    //                         container: {
                    //                             width: width < breakpoint ? "100%" : "30%",
                    //                             marginBottom: "2rem",
                    //                             marginTop: width < breakpoint ? "2rem" : "2rem",
                    //                         },
                    //                         image: {
                    //                             width: '100%',
                    //                             resizeMode: "cover",
                    //                             borderRadius: '50%',
                    //                         },
                    //                     }),
                    //                 },
                    //                 {
                    //                     type: 'text',
                    //                     text: 'É perfeito para você, professor(a) de Yoga, que:',
                    //                     style: (width) => ({
                    //                         fontSize: width < breakpoint ? 18 : 28,
                    //                         color: THEME.COLORS.PRIMARY_900,
                    //                         textAlign: "center",
                    //                         marginBottom: 25,
                    //                         fontFamily: THEME.FONTFAMILY.TEXTS,
                    //                         lineHeight: width < breakpoint ? 30 : 35,
                    //                         paddingHorizontal: width < breakpoint ? "0.5rem" : "1rem",
                    //                         fontWeight: 'bold',
                    //                     })
                    //                 },
                    //                 {
                    //                     type: 'text',
                    //                     text: '- Quer aperfeiçoar seu método de ensino, aprendendo novas ferramentas para criar as suas aulas de forma segura e criativa, fidelizando mais os seus alunos.\n- Quer se tornar uma professora mais preparada e com mais autonomia e confiança para se destacar no mercado.\n- Quer aprender sobre empreendedorismo para fazer do Yoga sua fonte de renda e única profissão.',
                    //                     style: (width) => ({
                    //                         fontSize: width < breakpoint ? 14 : 22,
                    //                         color: THEME.COLORS.TEXT_ABOUT,
                    //                         textAlign: "center",
                    //                         fontFamily: THEME.FONTFAMILY.TEXTS,
                    //                         lineHeight: width < breakpoint ? 30 : 35,
                    //                         paddingHorizontal: width < breakpoint ? "0.5rem" : "1rem",
                    //                         marginBottom: 25,
                    //                     })
                    //                 },
                    //                 {
                    //                     type: 'text',
                    //                     text: 'É perfeito para você, praticante, que:',
                    //                     style: (width) => ({
                    //                         fontSize: width < breakpoint ? 18 : 28,
                    //                         color: THEME.COLORS.PRIMARY_900,
                    //                         textAlign: "center",
                    //                         marginBottom: 25,
                    //                         fontFamily: THEME.FONTFAMILY.TEXTS,
                    //                         lineHeight: width < breakpoint ? 30 : 35,
                    //                         paddingHorizontal: width < breakpoint ? "0.5rem" : "1rem",
                    //                         fontWeight: 'bold',
                    //                     })
                    //                 },
                    //                 {
                    //                     type: 'text',
                    //                     text: '- Quer praticar no seu próprio horário e de qualquer lugar do mundo.\n- Tem pouco tempo livre e precisa às vezes adaptar a prática ao tempo que tem disponível.\n- Quer praticar um Yoga acessível e desmistificado, sem falsas promessas e nem misticismo.\n- Quer inserir uma ferramenta de autocuidado na sua rotina, para te trazer mais energia, equilíbrio e liberdade.\n- Quer praticar com segurança com professores experientes para aprender a ter mais autonomia na sua prática.\n- Quer aprender a alinhar as posturas de forma segura e correta para não se machucar na sua prática em casa.\n- Precisa de uma ferramenta para te ajudar a desacelerar.',
                    //                     style: (width) => ({
                    //                         fontSize: width < breakpoint ? 14 : 22,
                    //                         color: THEME.COLORS.TEXT_ABOUT,
                    //                         textAlign: "center",
                    //                         fontFamily: THEME.FONTFAMILY.TEXTS,
                    //                         lineHeight: width < breakpoint ? 30 : 35,
                    //                         paddingHorizontal: width < breakpoint ? "0.5rem" : "1rem",
                    //                         marginBottom: 35,
                    //                     })
                    //                 },
                    //                 {
                    //                     type: 'text',
                    //                     text: 'Aqui, você vai aprender a inserir o Yoga para o seu cotidiano, libertar sua mente através dos aprendizados da filosofia do Tantra Não Dual, ter acesso a ferramentas que vão te trazer mais autonomia e liberdade para praticar e para crescer na profissão.',
                    //                     style: (width) => ({
                    //                         fontSize: width < breakpoint ? 16 : 24,
                    //                         color: THEME.COLORS.TEXT_ABOUT,
                    //                         textAlign: "center",
                    //                         marginBottom: 25,
                    //                         fontFamily: THEME.FONTFAMILY.TEXTS,
                    //                         lineHeight: width < breakpoint ? 30 : 35,
                    //                         paddingHorizontal: width < breakpoint ? "0.5rem" : "1rem",
                    //                     })
                    //                 },
                    //                 {
                    //                     type: 'text',
                    //                     text: 'Pronta(o) para esse mergulho?',
                    //                     style: (width) => ({
                    //                         fontSize: width < breakpoint ? 18 : 28,
                    //                         color: THEME.COLORS.TEXT_ABOUT,
                    //                         textAlign: "center",
                    //                         marginBottom: 40,
                    //                         fontWeight: 'bold',
                    //                         fontFamily: THEME.FONTFAMILY.TEXTS,
                    //                         lineHeight: width < breakpoint ? 30 : 35,
                    //                         paddingHorizontal: width < breakpoint ? "0.5rem" : "1rem",
                    //                     })
                    //                 },
                    //                 {
                    //                     type: 'plansButton',
                    //                     text: 'Quero praticar com a Blix',
                    //                     buttonStyle: (width) => ({
                    //                         backgroundColor: THEME.COLORS.PRIMARY_900,
                    //                         paddingVertical: width < breakpoint ? '1rem' : '1.5rem',
                    //                         marginBottom: 10,
                    //                         paddingHorizontal: width < breakpoint ? '0.5rem' : '4rem',
                    //                         borderRadius: borderRadiusButtons,
                    //                         alignSelf: "center",
                    //                     }),
                    //                     textStyle: (width) => ({
                    //                         fontSize: width < breakpoint ? 18 : 25,
                    //                         color: THEME.COLORS.PRIMARY_700,
                    //                         fontFamily: THEME.FONTFAMILY.TEXTS,
                    //                         fontWeight: 'bold',
                    //                         textAlign: "center",
                    //                     }),
                    //                 },
                    //             ]
                    //         },
                    //     ],
                    //     containerStyles: (width) => ({
                    //         flexBasis: "auto",
                    //         backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                    //         paddingHorizontal: width < breakpoint ? "1rem" : "10rem",
                    //     }),
                    //     contentStyles: (width) => ({
                    //         justifyContent: width < breakpoint ? "center" : "center",
                    //         flexBasis: "auto",
                    //     }),
                    // },
                    // {
                    //     columns: [
                    //         {
                    //             style: (width, columnWidth) => ({
                    //                 justifyContent: 'space-between',
                    //                 alignItems: 'center',
                    //                 paddingTop: width < breakpoint ? "1rem" : "10rem",
                    //                 paddingBottom: width < breakpoint ? "1rem" : "5rem",
                    //                 width: width < breakpoint ? '100%' : columnWidth,
                    //                 alignItems: "center",
                    //             }),
                    //             items: [
                    //                 {
                    //                     type: 'multipleTexts',
                    //                     content: [
                    //                         {
                    //                             text: 'O que você vai encontrar no', style: (width) => ({
                    //                                 fontSize: width < breakpoint ? 23 : 40,
                    //                                 paddingTop: width < breakpoint ? "2rem" : "0rem",
                    //                                 paddingBottom: width < breakpoint ? "0.1rem" : "0px",
                    //                                 paddingHorizontal: width < breakpoint ? "0.1rem" : "0px",
                    //                                 color: THEME.COLORS.TEXT_ABOUT,
                    //                                 textAlign: "center",
                    //                                 fontFamily: THEME.FONTFAMILY.TEXTS
                    //                             })
                    //                         },
                    //                         {
                    //                             text: 'APLICATIVO', style: (width) => ({
                    //                                 fontSize: width < breakpoint ? 25 : 45,
                    //                                 padding: width < breakpoint ? "0.1rem" : "0px",
                    //                                 color: THEME.COLORS.PRIMARY_900,
                    //                                 textAlign: "center",
                    //                                 fontFamily: THEME.FONTFAMILY.TEXTS
                    //                             })
                    //                         },
                    //                     ],
                    //                 },
                    //                 {
                    //                     type: 'imageCards',
                    //                     arrowBackgroundColor: {
                    //                         lighter: `${THEME.COLORS.PRIMARY_800}A0`,
                    //                         darker: THEME.COLORS.PRIMARY_800
                    //                     },
                    //                     info: [
                    //                         {
                    //                             title: "COMECE POR AQUI",
                    //                             infos: "Nesse módulo, você vai entender mais sobre as aulas, os cursos e como funciona a comunidade YogandoBR.",
                    //                             image: require("../../../assets/FotoCategoria1.png"),
                    //                         },
                    //                         {
                    //                             title: "CONTEÚDOS GRATUITOS",
                    //                             infos: "Conheça um pouco do nosso trabalho e do universo do Yoga, através de vídeos gratuitos de prática e de filosofia.",
                    //                             image: require("../../../assets/FotoCategoria2.png"),
                    //                         },
                    //                         {
                    //                             title: "COMUNIDADE YOGANDOBR",
                    //                             infos: "Plataforma de aulas gravadas de prática de Yoga. Banco de aulas de vários níveis, durações (desde poucos minutos até mais de 1 hora), finalidades (alongamento, força, equilíbrio, ansiedade, insônia etc), professores e modalidades (Hatha Yoga, Vinyasa Flow Yoga etc), além de diversas práticas guiadas de relaxamento (Yoganidra) e meditação. Pratique quando e onde quiser na palma da sua mão. Novas aulas disponibilizadas semanalmente na plataforma.",
                    //                             image: require("../../../assets/FotoCategoria3.png"),
                    //                         },
                    //                         {
                    //                             title: "PRÁTICAS DE MEDITAÇÃO",
                    //                             infos: "Aulas teóricas sobre a meditação no contexto do Yoga e diversas práticas guiadas para você ganhar autonomia para praticar sozinho e para utilizar com seus alunos, caso seja professora. Novas aulas acrescentadas semanalmente na plataforma.",
                    //                             image: require("../../../assets/FotoCategoria4.png"),
                    //                         },
                    //                         {
                    //                             title: "PRÁTICAS DE RESPIRATÓRIAS",
                    //                             infos: "Aulas teóricas sobre a dinâmica da respiração e diversas práticas guiadas de exercícios respiratórios (Pranayamas) que vão te auxiliar a ter uma vida mais serena, conectada e cheia de energia!",
                    //                             image: require("../../../assets/FotoCategoria5.png"),
                    //                         },
                    //                         {
                    //                             title: "POSTURAS E SEUS AJUSTES",
                    //                             infos: "Tutoriais de execução de asanas (posturas) com ajustes e alinhamentos dentro das possibilidades de cada corpo, para uma prática segura.",
                    //                             image: require("../../../assets/FotoCategoria6.png"),
                    //                         },
                    //                         {
                    //                             title: "FILOSOFIA DO YOGA",
                    //                             infos: "Aqui no YogandoBR mergulhamos no vasto universo filosófico do Yoga, de forma desmistificada, simples e aplicada ao cotidiano, com as aulas teóricas, e-books, reflexões e textos complementares.",
                    //                             image: require("../../../assets/FotoCategoria7.png"),
                    //                         },
                    //                         {
                    //                             title: "CURSOS GRAVADOS",
                    //                             infos: "Temos diversas opções de cursos gravados que vão te ajudar a se aperfeiçoar e se aprofundar no universo do Yoga, tanto na filosofia quanto na prática. Cursos para praticantes e para professores de Yoga. Conheça todos os cursos abaixo!",
                    //                             image: require("../../../assets/FotoCategoria8.png"),
                    //                         },
                    //                         {
                    //                             title: "FORMAÇÃO PROFISSIONAL EM YOGA",
                    //                             infos: "Curso online de formação de professores em Hatha Vinyasa Flow Yoga (200 horas). Aulas ao vivo com interação próxima com as professoras e aulas gravadas para você estudar no seu próprio tempo e rever quantas vezes quiser pelo período de 1 ano na plataforma digital.",
                    //                             image: require("../../../assets/FotoCategoria9.png"),
                    //                         },
                    //                     ],
                    //                     cardStyle: (width) => ({
                    //                         width: "16rem",
                    //                         height: '100%',
                    //                         marginHorizontal: "1rem",
                    //                         backgroundColor: "transparent",
                    //                     }),
                    //                     cardCoverStyle: (width) => ({
                    //                         width: "100%",
                    //                         height: "9rem",
                    //                         borderRadius: "12px",
                    //                     }),
                    //                     titleStyle: (width) => ({
                    //                         fontWeight: "bold",
                    //                         paddingVertical: "0.5rem",
                    //                         textAlign: "left",
                    //                         fontSize: 20,
                    //                         fontWeight: "bold",
                    //                         color: THEME.COLORS.TEXT_ABOUT,
                    //                         fontFamily: THEME.FONTFAMILY.SUBTITLE
                    //                     }),
                    //                     infoStyle: (width) => ({
                    //                         textAlign: "left",
                    //                         fontSize: THEME.FONTSIZE.EXTRASMALL,
                    //                         color: THEME.COLORS.TEXT_ABOUT,
                    //                         fontFamily: THEME.FONTFAMILY.TEXTS
                    //                     }),
                    //                 },
                    //             ]
                    //         },
                    //     ],
                    //     gradientColors: {
                    //         colors: [
                    //             THEME.COLORS.PRIMARY_700,
                    //             'rgba(255, 255, 255, 1)',
                    //             'rgba(255, 255, 255, 1)',
                    //         ],
                    //         start: { x: 0, y: 0 },
                    //         end: { x: 1, y: 1 },
                    //     },
                    //     gradientBackgroundStyles: {
                    //         position: 'absolute',
                    //         left: 0,
                    //         right: 0,
                    //         top: 0,
                    //         bottom: 0,
                    //     },
                    //     containerStyles: (width) => ({
                    //         flexBasis: "auto",
                    //     }),
                    //     contentStyles: (width) => ({
                    //         justifyContent: width < breakpoint ? "center" : "center",
                    //         flexBasis: "auto",
                    //     }),
                    // },
                    // {
                    //     columns: [
                    //         {
                    //             items: [
                    //                 {
                    //                     type: 'iconCards',
                    //                     color: THEME.COLORS.PRIMARY_900,
                    //                     titleStyle: (width) => ({
                    //                         fontSize: 20,
                    //                         fontWeight: 'bold',
                    //                         marginBottom: 5,
                    //                         color: THEME.COLORS.TEXT_ABOUT,
                    //                         textAlign: "center",
                    //                         fontFamily: THEME.FONTFAMILY.TEXTS,
                    //                     }),
                    //                     descriptionStyle: (width) => ({
                    //                         fontSize: 18,
                    //                         textAlign: "center",
                    //                         color: THEME.COLORS.TEXT_ABOUT,
                    //                         fontFamily: THEME.FONTFAMILY.TEXTS,
                    //                     }),
                    //                     cardData: [
                    //                         {
                    //                             icon: 'shield-checkmark-outline',
                    //                             title: 'PAGAMENTO SEGURO',
                    //                             description: 'Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.',
                    //                         },
                    //                         {
                    //                             icon: 'calendar',
                    //                             title: 'ACESSO IMEDIATO',
                    //                             description: 'Após o pagamento, você receberá acesso ao conteúdo comprado no aplicativo.',
                    //                         },
                    //                         {
                    //                             icon: 'medal',
                    //                             title: '7 DIAS DE GARANTIA',
                    //                             description: 'Você poderá pedir a devolução de 100% do seu dinheiro dentro deste prazo.',
                    //                         },
                    //                     ]
                    //                 },
                    //             ]
                    //         },
                    //     ],
                    //     containerStyles: (width) => ({
                    //         flexBasis: "auto",
                    //         backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                    //         padding: "3rem",
                    //     }),
                    // },
                    // {
                    //     columns: [
                    //         {
                    //             items: [
                    //                 {
                    //                     type: 'text',
                    //                     text: 'Qual o valor do investimento?',
                    //                     style: (width) => ({
                    //                         fontSize: width < breakpoint ? 28 : 50,
                    //                         fontWeight: 'bold',
                    //                         color: THEME.COLORS.PRIMARY_700,
                    //                         paddingVertical: width < breakpoint ? '2rem' : '2rem',
                    //                         paddingHorizontal: width < breakpoint ? '1rem' : '3rem',
                    //                         fontFamily: THEME.FONTFAMILY.TITLE,
                    //                         width: "100%",
                    //                         textAlign: "center",
                    //                     })
                    //                 },
                    //                 {
                    //                     type: 'text',
                    //                     text: 'Teste grátis por 7 dias. Pagamento automático no boleto ou no cartão de crédito. Cancelamento quando desejar.',
                    //                     style: (width) => ({
                    //                         fontSize: width < breakpoint ? 14 : 20,
                    //                         color: THEME.COLORS.PRIMARY_700,
                    //                         fontFamily: THEME.FONTFAMILY.TITLE,
                    //                         paddingBottom: 20,
                    //                         paddingHorizontal: width < breakpoint ? '1rem' : '3rem',
                    //                         width: "100%",
                    //                         textAlign: "center",
                    //                     })
                    //                 },
                    //                 {
                    //                     type: 'plansCards',
                    //                     arrowBackgroundColor: {
                    //                         lighter: `${THEME.COLORS.PRIMARY_900}A0`,
                    //                         darker: THEME.COLORS.PRIMARY_900
                    //                     },
                    //                     cardStyle: (width, index) => ({
                    //                         width: width < breakpoint ? width * 0.8 : width * 0.30,
                    //                         height: "100%",
                    //                         borderWidth: "4px",
                    //                         borderStyle: "solid",
                    //                         borderColor: colors[(index + 1) % colors.length],
                    //                         backgroundColor: colors[index % colors.length], // Alternate the background color based on the index
                    //                     }
                    //                     ),
                    //                     titleStyle: (width, index) => ({
                    //                         fontSize: width < breakpoint ? 34 : 40,
                    //                         fontFamily: THEME.FONTFAMILY.TITLE,
                    //                         textAlign: "left",
                    //                         fontWeight: "bold",
                    //                         color: colors[(index + 1) % colors.length], // Alternate the text color based on the index
                    //                     }),
                    //                     pricingStyle: (width, index) => ({
                    //                         fontSize: width < breakpoint ? 38 : 50,
                    //                         textAlign: "left",
                    //                         fontWeight: "bold",
                    //                         color: colors[(index + 1) % colors.length], // Alternate the text color based on the index
                    //                         fontFamily: THEME.FONTFAMILY.TITLE,
                    //                     }),
                    //                     infoStyle: (width, index) => ({
                    //                         textAlign: "left",
                    //                         fontSize: width < breakpoint ? 14 : 16,
                    //                         color: colors[(index + 1) % colors.length], // Alternate the text color based on the index
                    //                         fontFamily: THEME.FONTFAMILY.TEXTS,
                    //                     }),
                    //                     buttonStyle: (width, index) => ({
                    //                         color: colors[(index + 1) % colors.length], // Alternate the text color based on the index
                    //                         style: {
                    //                             borderRadius: borderRadiusButtons,
                    //                             paddingVertical: width < breakpoint ? 14 : 18,
                    //                         },
                    //                         titleStyle: {
                    //                             fontWeight: 'bold',
                    //                             color: colors[index % colors.length], // Alternate the text color based on the index
                    //                             fontFamily: THEME.FONTFAMILY.TITLE,
                    //                             fontSize: width < breakpoint ? 16 : 22,
                    //                         },
                    //                     }),
                    //                 },
                    //             ]
                    //         },
                    //     ],
                    //     containerStyles: (width) => ({
                    //         flexBasis: "auto",
                    //         backgroundColor: THEME.COLORS.PRIMARY_900,
                    //         paddingVertical: "2rem"
                    //     }),
                    // },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingTop: width < breakpoint ? "1rem" : "5rem",
                                    paddingBottom: width < breakpoint ? "1rem" : "5rem",
                                    paddingHorizontal: width < breakpoint ? "0.5rem" : "2rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                }),
                                items: [
                                    {
                                        type: 'multipleTexts',
                                        content: [
                                            {
                                                text: 'Você pode comprar os', style: (width) => ({
                                                    fontSize: width < breakpoint ? 23 : 40,
                                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                                    paddingBottom: width < breakpoint ? "0.1rem" : "0px",
                                                    paddingHorizontal: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                            {
                                                text: 'CONTEÚDOS SEPARADAMENTE', style: (width) => ({
                                                    fontSize: width < breakpoint ? 25 : 45,
                                                    padding: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.PRIMARY_900,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                            {
                                                text: 'para ter os', style: (width) => ({
                                                    fontSize: width < breakpoint ? 23 : 50,
                                                    padding: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                            {
                                                text: 'RESULTADOS', style: (width) => ({
                                                    fontSize: width < breakpoint ? 30 : 50,
                                                    padding: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.PRIMARY_900,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS,
                                                })
                                            },
                                            {
                                                text: 'QUE VOCÊ', style: (width) => ({
                                                    fontSize: width < breakpoint ? 30 : 50,
                                                    padding: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS,
                                                })
                                            },
                                            {
                                                text: 'DESEJA', style: (width) => ({
                                                    fontSize: width < breakpoint ? 30 : 50,
                                                    padding: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.PRIMARY_900,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS,
                                                })
                                            },
                                        ],
                                    },
                                    {
                                        type: 'coursesCards',
                                        arrowBackgroundColor: {
                                            lighter: `${THEME.COLORS.PRIMARY_800}A0`,
                                            darker: THEME.COLORS.PRIMARY_800
                                        },
                                        cardStyle: (width) => ({
                                            width: "18rem",
                                            height: '100%',
                                            marginHorizontal: "1rem",
                                            backgroundColor: "transparent",
                                        }),
                                        cardCoverStyle: (width) => ({
                                            width: "100%",
                                            height: '10.12rem',
                                            borderRadius: '12px',
                                        }),
                                        titleStyle: (width) => ({
                                            textAlign: "left",
                                            fontSize: 20,
                                            fontWeight: "bold",
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            marginVertical: "0.5rem",
                                            fontFamily: THEME.FONTFAMILY.SUBTITLE
                                        }),
                                        priceStyle: (width) => ({
                                            textAlign: "left",
                                            fontSize: 20,
                                            fontWeight: 'bold',
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            paddingBottom: "0.5rem",
                                            fontFamily: THEME.FONTFAMILY.TEXTS
                                        }),
                                        infoStyle: (width) => ({
                                            textAlign: "left",
                                            fontSize: 15,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            marginVertical: "0.5rem",
                                            fontFamily: THEME.FONTFAMILY.TEXTS
                                        }),
                                        buttonStyle: (width) => ({
                                            backgroundColor: THEME.COLORS.PRIMARY_800,
                                            borderRadius: borderRadiusButtons,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                        textStyle: (width) => ({
                                            fontSize: width < breakpoint ? 18 : 22,
                                            color: THEME.COLORS.TEXT_BUTTON,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                    },
                                ]
                            },
                        ],
                        gradientColors: {
                            colors: [
                                THEME.COLORS.PRIMARY_700,
                                'rgba(255, 255, 255, 1)',
                                'rgba(255, 255, 255, 1)',
                            ],
                            start: { x: 0, y: 0 },
                            end: { x: 1, y: 1 },
                        },
                        gradientBackgroundStyles: {
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                        },
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },
                    // {
                    //     columns: [
                    //         {
                    //             style: (width, columnWidth) => ({
                    //                 justifyContent: 'space-between',
                    //                 alignItems: 'center',
                    //                 padding: width < breakpoint ? "1rem" : "2rem",
                    //                 width: width < breakpoint ? '100%' : columnWidth,
                    //                 alignItems: "center",
                    //                 order: width < breakpoint ? 1 : 2,
                    //             }),
                    //             items: [
                    //                 {
                    //                     type: 'text',
                    //                     text: 'PRECISA DE AJUDA PARA DECIDIR?',
                    //                     style: (width) => ({
                    //                         fontSize: width < breakpoint ? 28 : 30,
                    //                         fontWeight: 'bold',
                    //                         color: THEME.COLORS.PRIMARY_900,
                    //                         width: "100%",
                    //                         textAlign: "center",
                    //                         marginTop: 50,
                    //                         fontFamily: THEME.FONTFAMILY.TEXTS,
                    //                         padding: "10px",
                    //                     })
                    //                 },
                    //                 {
                    //                     type: 'text',
                    //                     text: 'Converse comigo, da forma que você preferir:',
                    //                     style: (width) => ({
                    //                         fontSize: width < breakpoint ? 24 : 28,
                    //                         color: THEME.COLORS.TEXT_ABOUT,
                    //                         width: "100%",
                    //                         textAlign: "center",
                    //                         marginBottom: 20,
                    //                         marginTop: 5,
                    //                         fontFamily: THEME.FONTFAMILY.TEXTS,
                    //                         padding: "10px",
                    //                     })
                    //                 },
                    //                 {
                    //                     type: 'iconCards',
                    //                     color: THEME.COLORS.PRIMARY_900,
                    //                     titleStyle: (width) => ({
                    //                         fontSize: 20,
                    //                         fontWeight: 'bold',
                    //                         marginBottom: 5,
                    //                         color: THEME.COLORS.TEXT_ABOUT,
                    //                         textAlign: "center",
                    //                         fontFamily: THEME.FONTFAMILY.TEXTS,
                    //                     }),
                    //                     descriptionStyle: (width) => ({
                    //                         fontSize: 16,
                    //                         textAlign: "center",
                    //                         color: THEME.COLORS.TEXT_ABOUT,
                    //                         fontFamily: THEME.FONTFAMILY.TEXTS,
                    //                     }),
                    //                     cardData: [
                    //                         {
                    //                             icon: 'logo-whatsapp',
                    //                             title: 'Fale comigo por WhatsApp',
                    //                             description: whatsappNumber,
                    //                             uri: whatsappLink,
                    //                         },
                    //                         {
                    //                             icon: 'logo-instagram',
                    //                             title: 'Instagram',
                    //                             description: instagramName,
                    //                             uri: instagramLink,
                    //                         },
                    //                         // add more icons here
                    //                         // {
                    //                         //     icon: 'mail',
                    //                         //     title: 'Atendimento por E-mail',
                    //                         //     description: 'contato@blixtecnologia.com.br',
                    //                         // },
                    //                     ],
                    //                 },
                    //             ]
                    //         },
                    //     ],
                    //     containerStyles: (width) => ({
                    //         flexBasis: "auto",
                    //         backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                    //     }),
                    //     contentStyles: (width) => ({
                    //         justifyContent: width < breakpoint ? "center" : "center",
                    //         flexBasis: "auto",
                    //     }),
                    // },
                    // {
                    //     columns: [
                    //         {
                    //             style: (width, columnWidth) => ({
                    //                 justifyContent: 'space-between',
                    //                 alignItems: 'center',
                    //                 padding: width < breakpoint ? "1rem" : "2rem",
                    //                 width: width < breakpoint ? '100%' : columnWidth,
                    //                 alignItems: "center",
                    //                 order: width < breakpoint ? 1 : 2,
                    //             }),
                    //             items: [
                    //                 {
                    //                     type: 'text',
                    //                     text: 'Perguntas frequentes',
                    //                     style: (width) => ({
                    //                         fontSize: width < breakpoint ? 25 : 34,
                    //                         color: THEME.COLORS.PRIMARY_900,
                    //                         textAlign: "center",
                    //                         textDecorationLine: "underline",
                    //                         textDecorationColor: THEME.COLORS.PRIMARY_900,
                    //                         marginBottom: 8,
                    //                         marginTop: 50,
                    //                         fontWeight: 'bold',
                    //                         fontFamily: THEME.FONTFAMILY.TITLE,
                    //                         padding: "10px",
                    //                     })
                    //                 },
                    //                 {
                    //                     type: 'FAQ',
                    //                     questionStyle: (width, expandedId, question) => ({
                    //                         flex: 1,
                    //                         fontSize: width < breakpoint ? 16 : 18,
                    //                         fontWeight: 'bold',
                    //                         fontFamily: THEME.FONTFAMILY.TEXTS,
                    //                         color: expandedId === question.id ? THEME.COLORS.PRIMARY_700 : THEME.COLORS.TEXT_ABOUT
                    //                     }),
                    //                     answerStyle: (width) => ({
                    //                         fontSize: width < breakpoint ? 16 : 18,
                    //                         fontFamily: THEME.FONTFAMILY.TEXTS,
                    //                         color: THEME.COLORS.TEXT_ABOUT
                    //                     }),
                    //                     containerStyle: (width) => ({
                    //                         backgroundColor: 'transparent',
                    //                         elevation: 5,
                    //                         margin: 10,
                    //                         overflow: 'hidden',
                    //                         borderBottomWidth: 1.5,
                    //                         borderBottomColor: THEME.COLORS.TEXT_ABOUT,
                    //                     }),
                    //                     questions: linkPlayStore !== null && linkAppleStore !== null ? [
                    //                         {
                    //                             id: 1,
                    //                             question: 'Tem aplicativo exclusivo?',
                    //                             answer: 'Tem sim! Você pode baixar o aplicativo nas lojas Apple Store ou Google Play em seu celular.',
                    //                         },
                    //                         {
                    //                             id: 2,
                    //                             question: 'Como faço para comprar um produto no aplicativo?',
                    //                             answer: 'Você compra e gerencia suas compras pela versão web do aplicativo, através desse link que você está.',
                    //                         },
                    //                         {
                    //                             id: 3,
                    //                             question: 'Como faço para acessar os conteúdos?',
                    //                             answer: 'Você pode acessar o conteúdo por aqui ou pelo aplicativo baixado nas lojas, basta clicar no botão “Visualizar Conteúdo”.',
                    //                         },
                    //                         {
                    //                             id: 4,
                    //                             question: 'Quais são as formas de pagamento aceitas?',
                    //                             answer: 'As opções de pagamento são cartão de crédito ou boleto.',
                    //                         },
                    //                         {
                    //                             id: 5,
                    //                             question: 'É preciso ter cadastro para ter acesso aos conteúdos gratuitos do aplicativo?',
                    //                             answer: 'Não é necessário, os conteúdos gratuitos estão abertos para todos visualizarem! Basta você acessar a opção “Visualizar Conteúdo” no aplicativo que você baixou ou acessar por aqui.',
                    //                         },
                    //                         {
                    //                             id: 6,
                    //                             question: 'Após eu finalizar o pagamento, irei receber algum e-mail de confirmação?',
                    //                             answer: 'Sim! Você receberá um e-mail de boas-vindas com suas credenciais de login e um e-mail de confirmação do pagamento.',
                    //                         },
                    //                         {
                    //                             id: 7,
                    //                             question: 'Como faço para me cadastrar no aplicativo? ',
                    //                             answer: 'O cadastro é feito somente para as pessoas que querem ter acesso aos conteúdos pagos. Após você escolher o produto que deseja comprar, você fará um cadastro e será redirecionado para a página de pagamento.',
                    //                         },
                    //                         {
                    //                             id: 8,
                    //                             question: 'Consigo cancelar a qualquer momento?',
                    //                             answer: 'Somente planos mensais podem ser cancelados a qualquer momento pelo aplicativo. Caso você tenha um plano de maior recorrência (trimestral, semestral ou anual), o cancelamento deve ser solicitado formalmente.',
                    //                         },
                    //                         {
                    //                             id: 9,
                    //                             question: 'Tive um problema com o pagamento, posso alterar a forma de pagamento ou os dados do cartão?',
                    //                             answer: 'Claro. Se algum problema acontecer com o pagamento, você será informado por e-mail e poderá alterar os dados por aqui, acessando "Meu perfil" no ícone superior esquerdo e clicando no botão "Editar meu plano".',
                    //                         },
                    //                     ] : [
                    //                         {
                    //                             id: 2,
                    //                             question: 'Como faço para comprar um produto no aplicativo?',
                    //                             answer: 'Você compra e gerencia suas compras pela versão web do aplicativo, através desse link que você está.',
                    //                         },
                    //                         {
                    //                             id: 3,
                    //                             question: 'Como faço para acessar os conteúdos?',
                    //                             answer: 'Você pode acessar o conteúdo por aqui, basta clicar no botão “Visualizar Conteúdo”.',
                    //                         },
                    //                         {
                    //                             id: 4,
                    //                             question: 'Quais são as formas de pagamento aceitas?',
                    //                             answer: 'As opções de pagamento são cartão de crédito ou boleto.',
                    //                         },
                    //                         {
                    //                             id: 5,
                    //                             question: 'É preciso ter cadastro para ter acesso aos conteúdos gratuitos do aplicativo?',
                    //                             answer: 'Não é necessário, os conteúdos gratuitos estão abertos para todos visualizarem! Basta você acessar a opção “Visualizar Conteúdo” e acessar os conteúdos desbloqueados.',
                    //                         },
                    //                         {
                    //                             id: 6,
                    //                             question: 'Após eu finalizar o pagamento, irei receber algum e-mail de confirmação?',
                    //                             answer: 'Sim! Você receberá um e-mail de boas-vindas com suas credenciais de login e um e-mail de confirmação do pagamento.',
                    //                         },
                    //                         {
                    //                             id: 7,
                    //                             question: 'Como faço para me cadastrar no aplicativo? ',
                    //                             answer: 'O cadastro é feito somente para as pessoas que querem ter acesso aos conteúdos pagos. Após você escolher o produto que deseja comprar, você fará um cadastro e será redirecionado para a página de pagamento.',
                    //                         },
                    //                         {
                    //                             id: 8,
                    //                             question: 'Consigo cancelar a qualquer momento?',
                    //                             answer: 'Somente planos mensais podem ser cancelados a qualquer momento pelo aplicativo. Caso você tenha um plano de maior recorrência (trimestral, semestral ou anual), o cancelamento deve ser solicitado formalmente.',
                    //                         },
                    //                         {
                    //                             id: 9,
                    //                             question: 'Tive um problema com o pagamento, posso alterar a forma de pagamento ou os dados do cartão?',
                    //                             answer: 'Claro. Se algum problema acontecer com o pagamento, você será informado por e-mail e poderá alterar os dados por aqui, acessando "Meu perfil" no ícone superior esquerdo e clicando no botão "Editar meu plano".',
                    //                         },
                    //                     ]
                    //                 }
                    //             ]
                    //         },
                    //     ],
                    //     containerStyles: (width) => ({
                    //         flexBasis: "auto",
                    //         backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                    //         paddingHorizontal: width < breakpoint ? "2rem" : "10rem",
                    //     }),
                    //     contentStyles: (width) => ({
                    //         justifyContent: width < breakpoint ? "center" : "center",
                    //         flexBasis: "auto",
                    //     }),
                    // },
                    // {
                    //     columns: [
                    //         {
                    //             style: (width, columnWidth) => ({
                    //                 justifyContent: 'space-between',
                    //                 alignItems: 'center',
                    //                 paddingVertical: width < breakpoint ? "2rem" : "5rem",
                    //                 width: width < breakpoint ? '100%' : columnWidth,
                    //                 alignItems: "center",
                    //             }),
                    //             items: [
                    //                 {
                    //                     type: 'text',
                    //                     text: 'DEPOIMENTOS',
                    //                     style: (width) => ({
                    //                         fontSize: width < breakpoint ? 26 : 38,
                    //                         color: THEME.COLORS.TEXT_ABOUT,
                    //                         textAlign: "center",
                    //                         marginBottom: 20,
                    //                         fontFamily: THEME.FONTFAMILY.SUBTITLE,
                    //                     })
                    //                 },
                    //                 {
                    //                     type: 'testimonials',
                    //                     arrowBackgroundColor: {
                    //                         lighter: `${THEME.COLORS.PRIMARY_800}A0`,
                    //                         darker: THEME.COLORS.PRIMARY_800
                    //                     },
                    //                     info: [
                    //                         {
                    //                             title: "~ John",
                    //                             infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque. Aut numquam corporis non iste assumend."',
                    //                             //image: require("../../../assets/Testimonial1.jpg"), //add image if the testimonial is an image
                    //                         },
                    //                         {
                    //                             title: "~ Kamesh",
                    //                             infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque."',
                    //                             //image: require("../../../assets/Testimonial2.jpg"), //add image if the testimonial is an image
                    //                         },
                    //                         {
                    //                             title: "~ Nobita",
                    //                             infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque. Aut numquam corporis non iste assumend. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque."',
                    //                             //image: require("../../../assets/Testimonial3.jpg"), //add image if the testimonial is an image
                    //                         },
                    //                         {
                    //                             title: "~ Juliet",
                    //                             infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque."',
                    //                             //image: require("../../../assets/Testimonial4.jpg"), //add image if the testimonial is an image
                    //                         },
                    //                         {
                    //                             title: "~ Peter",
                    //                             infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque. Aut numquam corporis non iste assumend. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque."',
                    //                             //image: require("../../../assets/Testimonial5.jpg"), //add image if the testimonial is an image
                    //                         },
                    //                         {
                    //                             title: "~ Lia",
                    //                             infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque. Aut numquam corporis non iste assumend."',
                    //                             //image: require("../../../assets/Testimonial6.jpg"), //add image if the testimonial is an image
                    //                         },
                    //                     ],
                    //                     cardStyle: (width) => ({
                    //                         width: "20rem",
                    //                         height: '100%',
                    //                         marginHorizontal: "2rem",
                    //                         backgroundColor: "transparent",
                    //                     }),
                    //                     titleStyle: (width) => ({
                    //                         paddingVertical: "1rem",
                    //                         textAlign: "center",
                    //                         fontSize: THEME.FONTSIZE.STANDARD,
                    //                         color: THEME.COLORS.PRIMARY_900,
                    //                         fontFamily: THEME.FONTFAMILY.SUBTITLE
                    //                     }),
                    //                     infoStyle: (width) => ({
                    //                         textAlign: "center",
                    //                         padding: "0.5rem",
                    //                         fontSize: THEME.FONTSIZE.SMALL,
                    //                         color: THEME.COLORS.TEXT_ABOUT,
                    //                         fontFamily: THEME.FONTFAMILY.TEXTS
                    //                     }),
                    //                 },
                    //             ]
                    //         },
                    //     ],
                    //     containerStyles: (width) => ({
                    //         flexBasis: "auto",
                    //         backgroundColor: THEME.COLORS.BACKGROUND_ABOUT
                    //     }),
                    //     contentStyles: (width) => ({
                    //         justifyContent: width < breakpoint ? "center" : "center",
                    //         flexBasis: "auto",
                    //     }),
                    // },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "2rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                    alignItems: "center",
                                    order: width < breakpoint ? 1 : 2,
                                }),
                                items: [
                                    {
                                        type: 'text',
                                        text: 'Vamos juntos nessa jornada! Inscreva-se agora mesmo!',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 16 : 24,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "center",
                                            marginTop: 40,
                                            marginBottom:40,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            paddingHorizontal: width < breakpoint ? "0.5rem" : "2rem",
                                        })
                                    },
                                    {
                                        type: 'plansButton',
                                        text: 'QUERO INICIAR',
                                        buttonStyle: (width) => ({
                                            backgroundColor: THEME.COLORS.PRIMARY_900,
                                            paddingVertical: width < breakpoint ? '1rem' : '1rem',
                                            paddingHorizontal: width < breakpoint ? '2rem' : '4rem',
                                            borderRadius: borderRadiusButtons,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            alignSelf: width < breakpoint ? "center" : "center",
                                            marginBottom: 40,
                                            marginTop: 40,
                                        }),
                                        textStyle: (width) => ({
                                            fontSize: 18,
                                            fontWeight: 'bold',
                                            color: THEME.COLORS.PRIMARY_700,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            textAlign: width < breakpoint ? "flex-end" : "center",
                                        }),
                                    }
                                ]
                            },
                        ],
                        gradientColors: {
                            colors: [
                                THEME.COLORS.PRIMARY_700,
                                'rgba(255, 255, 255, 1)',
                                'rgba(255, 255, 255, 1)',
                            ],
                            start: { x: 0, y: 0 },
                            end: { x: 1, y: 1 },
                        },
                        gradientBackgroundStyles: {
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                        },
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },

                    // Add more sections for this client here
                ],
            },
            // {
            //     name: "plans",
            //     sections: [
            //         {
            //             columns: [
            //                 {
            //                     items: [
            //                         {
            //                             type: 'text',
            //                             text: 'Qual o valor do investimento?',
            //                             style: (width) => ({
            //                                 fontSize: width < breakpoint ? 28 : 50,
            //                                 fontWeight: 'bold',
            //                                 color: THEME.COLORS.PRIMARY_700,
            //                                 paddingVertical: width < breakpoint ? '2rem' : '2rem',
            //                                 paddingHorizontal: width < breakpoint ? '1rem' : '3rem',
            //                                 fontFamily: THEME.FONTFAMILY.TITLE,
            //                                 width: "100%",
            //                                 textAlign: "center",
            //                             })
            //                         },
            //                         {
            //                             type: 'text',
            //                             text: 'Teste grátis por 7 dias. Pagamento automático no boleto ou no cartão de crédito. Cancelamento quando desejar.',
            //                             style: (width) => ({
            //                                 fontSize: width < breakpoint ? 14 : 20,
            //                                 color: THEME.COLORS.PRIMARY_700,
            //                                 fontFamily: THEME.FONTFAMILY.TITLE,
            //                                 paddingBottom: 20,
            //                                 paddingHorizontal: width < breakpoint ? '1rem' : '3rem',
            //                                 width: "100%",
            //                                 textAlign: "center",
            //                             })
            //                         },
            //                         {
            //                             type: 'plansCards',
            //                             arrowBackgroundColor: {
            //                                 lighter: `${THEME.COLORS.PRIMARY_900}A0`,
            //                                 darker: THEME.COLORS.PRIMARY_900
            //                             },
            //                             cardStyle: (width, index) => ({
            //                                 width: width < breakpoint ? width * 0.8 : width * 0.28,
            //                                 height: "100%",
            //                                 borderWidth: "4px",
            //                                 borderStyle: "solid",
            //                                 borderColor: colors[(index + 1) % colors.length],
            //                                 backgroundColor: colors[index % colors.length], // Alternate the background color based on the index
            //                             }
            //                             ),
            //                             titleStyle: (width, index) => ({
            //                                 fontSize: width < breakpoint ? 34 : 40,
            //                                 fontFamily: THEME.FONTFAMILY.TITLE,
            //                                 textAlign: "left",
            //                                 fontWeight: "bold",
            //                                 color: colors[(index + 1) % colors.length], // Alternate the text color based on the index
            //                             }),
            //                             pricingStyle: (width, index) => ({
            //                                 fontSize: width < breakpoint ? 38 : 50,
            //                                 textAlign: "left",
            //                                 fontWeight: "bold",
            //                                 color: colors[(index + 1) % colors.length], // Alternate the text color based on the index
            //                                 fontFamily: THEME.FONTFAMILY.TITLE,
            //                             }),
            //                             infoStyle: (width, index) => ({
            //                                 textAlign: "left",
            //                                 fontSize: width < breakpoint ? 14 : 16,
            //                                 color: colors[(index + 1) % colors.length], // Alternate the text color based on the index
            //                                 fontFamily: THEME.FONTFAMILY.TEXTS,
            //                             }),
            //                             buttonStyle: (width, index) => ({
            //                                 color: colors[(index + 1) % colors.length], // Alternate the text color based on the index
            //                                 style: {
            //                                     borderRadius: borderRadiusButtons,
            //                                     paddingVertical: width < breakpoint ? 14 : 18,
            //                                 },
            //                                 titleStyle: {
            //                                     fontWeight: 'bold',
            //                                     color: colors[index % colors.length], // Alternate the text color based on the index
            //                                     fontFamily: THEME.FONTFAMILY.TITLE,
            //                                     fontSize: width < breakpoint ? 16 : 22,
            //                                 },
            //                             }),
            //                         },
            //                     ]
            //                 },
            //             ],
            //             containerStyles: (width) => ({
            //                 flexBasis: "auto",
            //                 flex: 1,
            //                 backgroundColor: THEME.COLORS.PRIMARY_900,
            //                 paddingVertical: "2rem"
            //             }),
            //         },
            //     ],
            // },
            // add more pages as needed
        ],
    },
    // add more clients as needed
];
